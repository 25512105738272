<script setup lang="ts">
import type { VideoDetailRes } from '~/api/detail/model'
import { MediaTypeEnum } from '~/enums/const'
import type { MediaType } from '~/types'

const props = withDefaults(
  defineProps<{
    type: string
    infos: VideoDetailRes
  }>(),
  {}
)

const emits = defineEmits<{ refresh: [value?: unknown] }>()
// 显示类型信息
const calculateType = computed<string>(() => {
  return MediaTypeEnum[props.type as MediaType]
})
const refresh = () => emits('refresh')
</script>

<template>
  <div class="text-left">
    <!-- 简介 -->
    <div class="flex">
      <span class="text-size-3.75 font-500 xl:text-size-10 text-#E8E8E8"> {{ infos.videoName }} </span>
      <Collect :video-info="infos" @handle-refresh="refresh" />
    </div>
    <div>
      <span>{{ calculateType }} </span>
      <span> ｜ </span>
      <span> {{ infos.tagName }} </span>
      <span> ｜ </span>
      <span> {{ infos.years }} </span>
    </div>
    <div class="flex" my-2>
      <p class="min-w-12 text-nowrap op-50 xl:min-w-12">地区：</p>
      <p class="flex flex-wrap">
        {{ infos.area }}
      </p>
    </div>
    <div class="flex" my-2>
      <p class="min-w-12 text-nowrap op-50 xl:min-w-12">主演：</p>
      <p class="break-all">
        {{ infos.mainActor }}
      </p>
    </div>
    <div class="flex" my-2>
      <p class="min-w-12 text-nowrap op-50 xl:min-w-12">描述：</p>
      <p class="break-all">
        {{ infos.videoDesc }}
      </p>
    </div>
  </div>
</template>
