<script lang="ts" setup>
import type { HasSeriesMediaType, SeriesList } from '~/types'
import { splitArrayBySize } from '~/utils/helper'

const props = withDefaults(
  defineProps<{
    splice?: number // 剧集分割长度
    list: SeriesList[] | any[] // 剧集列表
    type: HasSeriesMediaType // 媒体类型
    hasRadius?: boolean // 背景是否有圆角
  }>(),
  {
    splice: 30,
    list: () => {
      return []
    }
  }
)

const isMobile = computed(() => {
  return useDevice().isMobile
})

const router = useRouter()
const route = useRoute()
const serialId = useRouteParam<string>('serial')
const activeSeries = ref(0)
const seriesList = ref<SeriesList[]>(props.list.slice(0, props.splice))
// 当前剧集集数标签
const curSeries = ref('')

watch(
  () => route.query,
  (val) => {
    const copyList = JSON.parse(JSON.stringify(props.list))
    const arr = splitArrayBySize(copyList, props.splice)
    if (serialId.value && val.tab) {
      seriesList.value = Object.values(arr)[Number(val.tab)] || []
      const obj = seriesList.value.find((item) => item.videoSeriesId === serialId.value)
      curSeries.value = obj?.videoSeriesId || ''
      activeSeries.value = (val.tab && Number(val.tab)) || 0
    } else {
      curSeries.value = seriesList.value[0]?.videoSeriesId || ''
    }
  },
  { immediate: true, deep: true }
)

// 拆解数组
const virtualList = computed(() => {
  const copyList = JSON.parse(JSON.stringify(props.list))
  return splitArrayBySize(copyList, props.splice)
})

function handleTabChange(series: number, item: any[]) {
  activeSeries.value = series
  seriesList.value = item
}
function handlePlayVideo(arg: SeriesList) {
  curSeries.value = arg?.videoSeriesId || ''
  router.push(`/play/${props.type}/${arg.videoSiteId}/${arg.videoSeriesId}?tab=${activeSeries.value}`)
}
</script>

<template>
  <div class="w-100% p-3 bg-242A4A xl:p-4" :class="{ 'rounded-md': hasRadius }">
    <p mb-2 xl:font-size-4>
      选择剧集
    </p>
    <ul v-if="list.length > splice" flex class="mb-2 font-size-3 md:my-3 xl:font-size-4">
      <li
        v-for="(item, key, index) in virtualList"
        :key="key"
        :class="{ 'ct-f97316': activeSeries === index }"
        class="mr-5 cursor-pointer"
        @click="handleTabChange(index, item)"
      >
        {{ key }}
      </li>
    </ul>
    <div :class="isMobile ? 'channerl-scroll flex' : 'flex flex-wrap'">
      <div
        v-for="series in seriesList"
        :key="series.videoSiteId"
        :to="`/play/${type}/${series.videoSiteId}/${series.videoSeriesId}?tab=${activeSeries}`"
        mb-2
        mr-2
        h-8
        flex
        cursor-pointer
        items-center
        justify-center
        rounded-md
        px-4
        font-size-3
        bg-3d4676
        md:h-10
        xl:font-size-4
        :class="{ 'bg-f97316': curSeries === series.videoSeriesId }"
        hover="bg-f97316"
        @click="handlePlayVideo(series)"
      >
        {{ series.seriesNum }}
      </div>
    </div>
  </div>
</template>

<style>
.channerl-scroll {
  width: 100%;
  /* overflow-y: hidden; */
  overflow-x: scroll;
  white-space: nowrap;
}
.channerl-scroll::-webkit-scrollbar {
  display: none;
  /* height: 12px; */
}
</style>
