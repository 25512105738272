import { getUserLikeVideo, getVideoDetail } from '~/api/detail'
import type { UserLikeVideoRes, VideoDetailRes } from '~/api/detail/model'
import { getVideoDetailForServer } from '~/api/server'
import type { Media, SeriesList } from '~/types'

export const useMedia = defineStore('media', {
  state: () => ({
    mediaDetail: <VideoDetailRes>{},
    guessPreferDetail: <UserLikeVideoRes>{},
    popupShow: Boolean
  }),
  getters: {
    // 详情图片
    videoVerticalUrl: (state) => {
      return state.mediaDetail?.videoVerticalUrl
    },
    // 剧集列表
    videoSeriesList: (state) => {
      return (state.mediaDetail?.ysVideoSeriesPOList as SeriesList[]) || []
    },
    // 猜你喜欢列表
    guessPreferList: (state) => {
      return (state.guessPreferDetail?.record as Media[]) || []
    },
    // 猜你喜欢总页数
    gussPreferTotalPage: (state) => {
      return state.guessPreferDetail?.totalPage
    }
  },

  actions: {
    // 获取详情
    async handleMediaDetail(arg: string) {
      const requestApi = process.server ? getVideoDetailForServer : getVideoDetail

      const { data } = await requestApi({ videoSiteId: arg })
      this.mediaDetail = data || {}
    },
    // 猜你喜欢
    async handleGuessPrefer(args: any) {
      const { data } = await getUserLikeVideo({ pageSize: 10, ...args })
      this.guessPreferDetail = data || []
    }
  }
})
