<script setup lang="ts">
import { UserLoginDialog } from '#components'
import { setAddUserCollection, setCanceUserCollection } from '~/api/detail'
import { CODE_INVALID_AUTH } from '~/enums/code'

const props = withDefaults(defineProps<{ videoInfo: any }>(), {})
const emits = defineEmits(['handleRefresh'])
const loginDialogRef = ref<InstanceType<typeof UserLoginDialog> | null>(null)
const userStore = useUser()
const have = ref(false)
const isLoading = ref(false)
watch(
  () => props.videoInfo.isCollection,
  (newVal) => {
    nextTick(() => {
      have.value = newVal === 1
      isLoading.value = false
    })
  },
  { immediate: true }
)

watch(
  () => loginDialogRef.value?.loginVisible,
  (val) => {
    !val && (isLoading.value = false)
  }
)

async function handelCollect() {
  console.log(isLoading.value)

  if (isLoading.value) return
  isLoading.value = true
  if (userStore.isRealUser) {
    try {
      const id = props.videoInfo.videoSiteId
      if (have.value) {
        await setCanceUserCollection({ userFlag: 2, videoId: id })
      } else {
        await setAddUserCollection({ userFlag: 2, videoId: id })
      }
      emits('handleRefresh')
    } catch (error) {
      if (CODE_INVALID_AUTH.includes(error.code)) {
        loginDialogRef.value && (loginDialogRef.value.loginVisible = true)
      }
      emits('handleRefresh')
    }
  } else {
    loginDialogRef.value && (loginDialogRef.value.loginVisible = true)
  }
}
</script>

<template>
  <!-- 收藏 -->
  <button ml-2 @click="handelCollect">
    <BaseIcon :color="have ? '#F97316' : '#E8E8E8'" :name="have ? 'activatiCollect' : 'collect'" size="1.2" />
  </button>
  <UserLoginDialog ref="loginDialogRef" />
</template>
